import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { AuthorizationService } from './services/authorization.service';
import { URLHelper } from './shared/helper/url-helper';
import { provideHttpClient } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(),
    provideRouter(routes),
    provideAnimations(),
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthorizationService], multi: true},
    importProvidersFrom( TranslateModule.forRoot())
  ]
};

const PreLoginRoutes = ["register", "landing", "invitation", "forgot-password"];

function isPreLoginRoute(url: string) {
  for(const route of PreLoginRoutes) {
    if(url.includes(route)) {
      return true;
    }
  }
  return false;
}

function validateToken(authorizationService: AuthorizationService): Promise<boolean> {
  return new Promise((resolve) => {
    try{
      let token = localStorage.getItem("token");

      if (!token || token === " " || token === "Bearer ") {
        resolve(false);
        return;
      }

      authorizationService.checkValidToken()
        .toPromise()
        .then((res: any) => {
          resolve(res.success);
        })
        .catch(async () => {
          localStorage.removeItem("token");
          resolve(false);
      });
    } catch (error) {
      localStorage.removeItem("token");
      resolve(false);
    }
  });
}

export function initializeApp(authorizationService: AuthorizationService) {
  return () => new Promise(async (resolve) => {
    const hrefUrl = location.href;
    const urlParams = URLHelper.getQueryParams(decodeURI(hrefUrl));
    
    if (urlParams.hasOwnProperty("code") || urlParams.hasOwnProperty("t") || isPreLoginRoute(hrefUrl)) {
      resolve(true);
      return;
    }
    
    let isBaseURL = isBaseUrl(location.href);
    if(isBaseURL){
      authorizationService.gotoLoginPage();
      resolve(true);
      return;
    }

    resolve(true);
    
    // let isValidToken = await validateToken(authorizationService);

    // if (!isValidToken) {
    //   let isTokenRefreshed = await refreshToken(authorizationService);
        
    //   if(!isTokenRefreshed) {     
    //     authorizationService.gotoURLPage(hrefUrl);
    //     resolve(true);
    //     return;
    //   }

    // }

    // resolve(await (loadLanguageData(translate, http, authorizationService))());

  });
}

function isBaseUrl(url: string) {
  let baseUrlPattern = /^https?:\/\/[a-zA-Z0-9-]+\.com\/?$/;
  if(environment.ENV === "DEV"){
    baseUrlPattern = /^(https?:\/\/)?localhost:3000\/?$/;
  }
  const matches = url.match(baseUrlPattern);

  if (matches) {
    return true;
  }

  return false;
}