import { Routes } from '@angular/router';
import { NotfoundComponent } from './system-error/not-found/notfound.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pre-login/pre-login.module').then(m => m.PreLoginModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./post-login/post-login.module').then(m => m.PostLoginModule)
  },
  {
    path: '404',
    loadComponent: () => import('./system-error/not-found/notfound.component').then(m => m.NotfoundComponent)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];


