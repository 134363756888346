export class ApplicationSettingModel {
  id: number;
  localeId: number;
  name: string;
  key: string;
  isActive: boolean;
  canEdit: boolean;
  translateIsoCode: string;
  children: ApplicationSettingModel[];
  destinationLocaleId: number;

  fromJson(json): ApplicationSettingModel {
    if (!json)
      return this;

    this.id = json.id;
    this.localeId = json.localeId;
    this.name = json.name;
    this.key = json.key;
    this.isActive = json.isActive;
    this.canEdit = json.canEdit;
    this.translateIsoCode = json.translateIsoCode || '';
    this.children = json.children ? json.children.map(data => new ApplicationSettingModel().fromJson(data)) : [];
    this.destinationLocaleId = json.destinationLocaleId;

    return this;
  }
}
