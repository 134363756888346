import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { tap, catchError, filter, map } from 'rxjs/operators';
import { ApplicationLanguage, Constants, CookieKey, Utility } from '../shared/helper';
import { ApplicationSettingModel } from '../models/v2/application-setting.model';
import { ApiConstants } from '../shared/helper/api-constants';

interface CachedTranslation {
  data;
  expiry: number; // Timestamp in milliseconds
}

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private currentLang = new BehaviorSubject<string>('');
  currentLang$ = this.currentLang.asObservable().pipe(filter(lang => !!lang));
  private readonly CACHE_KEY_PREFIX = 'translations_';
  private readonly CACHE_EXPIRY_MS = 24 * 60 * 60 * 1000; // 24 hours

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) { }

  get headers() {
    const tokenKey = localStorage.getItem(CookieKey.Token);

    const headers = new HttpHeaders({
      'Cache-control': 'no-cache',
      'Pragma': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization': tokenKey,
      'Content-Type': 'application/json'
    });
    return headers;
  }

  GetApplicationLanguages(browserCultureLang: string) {
    const key = CookieKey.currentApplicationLanguageKey + `_${browserCultureLang}`;
    const selectedLanguages = Utility.getLocalStorageWithExpiry(key);

    if (selectedLanguages == null) {
      const url = `${Constants.DomainURL}/${Constants.Setting}/GetApplicationLanguage?lang=${browserCultureLang}`;
      return (
        this.http
          .get(url, { headers: this.headers })
          .pipe(map((response: Response) => {
            Utility.setLocalStorageWithExpiry(key, response, Constants.Cache_8_Hours);
            return response;
          }),
            catchError((error) => {
              return throwError(error.statusText);
            }))
      );
    }
    else {
      return of(selectedLanguages);
    }

  }

  loadApplicationLanguage() {
    let url = `${Constants.DomainURL_V2}/${Constants.ApplicationV2}`;
    this.getAppSettings().subscribe((response) => {
      let applicationLanguages: ApplicationSettingModel[] = [];

      if (response) {
        applicationLanguages = response.map((json) => new ApplicationSettingModel().fromJson(json));
      }

      let languages: string[] = [];

      const start = ApplicationLanguage.SettingPrefixLanguage.length + 1;
      const languageCodes = applicationLanguages.map(language => {
        if (!language)
          return null;

        let code = language.key.substring(start, language.key.length);

        if (code.indexOf('-') !== -1) {
          code = code.substring(0, code.indexOf('-'));
        }

        return code.toLowerCase();
      });

      if (languageCodes && languageCodes.length > 0) {
        languageCodes.forEach(code => {
          if (languages.indexOf(code) < 0) {
            languages.push(code);
          }
        });
      }

      if (!languages || languages.length <= 0) {
        languages = ['en', 'de', 'fr', 'ar', 'zh', 'da', 'nl', 'hu', 'it', 'ja', 'pl', 'pt', 'ru', 'sl', 'es', 'sv', 'tr', 'uk'];
      }

      this.translate.addLangs(languages);

      let browserLang = this.translate.getBrowserLang();
      const language = this.translate.getLangs().find(lan => lan.toLowerCase() === browserLang.toLowerCase());
      if (!language) {
        browserLang = 'en';
      }

      let browserCultureLang = this.translate.getBrowserCultureLang();

      if (!browserCultureLang.startsWith(browserLang)) {
        browserCultureLang = browserLang;
      }
      localStorage.setItem(Constants.CurrentCultureLanguage, browserCultureLang);

      const cachedData = this.getCachedTranslations(browserCultureLang);

      if (cachedData) {
        this.setTranslationsAndLanguage(browserCultureLang, cachedData.data);
        return; // Return from cache
      }

      this.GetApplicationLanguages(browserCultureLang).subscribe((res) => {
        this.cacheTranslations(browserCultureLang, JSON.parse(res.result));
        this.setTranslationsAndLanguage(browserCultureLang, JSON.parse(res.result));
      }, error => {
        url = `${Constants.DomainURL_V2}/${Constants.ApplicationV2}/${ApiConstants.GetDefaultApplicationLanguage}`;
        this.http.get(url, { headers: this.headers }).subscribe((res) => {
          this.cacheTranslations(browserCultureLang, res);
          this.setTranslationsAndLanguage(browserCultureLang, res);
        });
      });
    });
  }

  private getCachedTranslations(lang: string): CachedTranslation | null {
    const cacheKey = this.getCacheKey(lang);
    const storedData = localStorage.getItem(cacheKey);

    if (storedData) {
      try {
        const cachedTranslation: CachedTranslation = JSON.parse(storedData);
        if (cachedTranslation.expiry > Date.now()) {
          return cachedTranslation;
        } else {
          localStorage.removeItem(cacheKey); // Remove expired cache
        }
      } catch (error) {
        console.error('Error parsing cached translations', error);
        localStorage.removeItem(cacheKey); // Remove invalid cache
      }
    }
    return null;
  }

  private cacheTranslations(lang: string, data): void {
    const cacheKey = this.getCacheKey(lang);
    const expiry = Date.now() + this.CACHE_EXPIRY_MS;
    const cachedTranslation: CachedTranslation = { data, expiry };
    localStorage.setItem(cacheKey, JSON.stringify(cachedTranslation));
  }

  private getCacheKey(lang: string): string {
    return this.CACHE_KEY_PREFIX + lang;
  }

  private setTranslationsAndLanguage(lang: string, translations) {
    this.translate.setTranslation(lang, translations);
    this.translate.use(lang);
    this.currentLang.next(lang);
  }

  getAppSettings() {

    const appSettings = Utility.getLocalStorageWithExpiry(CookieKey.appSettingsKey);

    if (appSettings == null) {
      const url = `${Constants.DomainURL_V2}/${Constants.ApplicationV2}`;
      return (
        this.http
          .get(url, { headers: this.headers })
          .pipe(map((response: Response) => {
            Utility.setLocalStorageWithExpiry(CookieKey.appSettingsKey, response, Constants.Cache_8_Hours);
            return response;
          }),
            catchError((error) => {
              return throwError(error.statusText);
            }))
      );
    }
    else {
      return of(appSettings);
    }
  }

  getGetApplicationLanguages(browserCultureLang: string) {

    const url = `${Constants.DomainURL}/${Constants.Setting}/GetApplicationLanguage?lang=${browserCultureLang}`;
    return (
      this.http
        .get(url, { headers: this.headers })
        .pipe(
          catchError((error) => {
            return throwError(error.statusText);
          }))
    );

  }
}